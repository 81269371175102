/** color schema */
$color-white: #fff;
$color-black: #22272e;
$color-gray: #777471;
$color-stone: #edeae6;
$color-beige: #f9f8f4;
$color-stone-tint: #f6f4f2;
$color-yellow: #f5d652;
$color-canary: #fff5ca;
$color-canary-tint: #fffae5;
$color-cobalt: #0074e4;
$color-sky: #b6dbff;
$color-sky-tint: #daedfe;
$color-sky-light: #eef8ff;
$color-blurple: #5522fa;
$color-lavender: #d5d2ff;
$color-lavender-tint: #eae8fe;
$color-purple: #7700a2;
$color-pink: #cb84de;
$color-pink-tint: #efdaf5;
$color-salmon: #ec8580;
$color-peach: #fee5da;
$color-peach-tint: #fef2ed;
$color-teal: #5ceace;
$color-mint: #b3f8eb;
$color-mint-tint: #d8f9f0;
$color-shadow: rgba(0,0,0,0.13);
$color-shadow-dark: rgba(0,0,0,0.21);
$color-text: $color-black;
$color-background: $color-beige;
$color-sheet: $color-white;
$color-neutral: $color-gray;
$color-link: $color-cobalt;
$color-accent: $color-blurple;

/** spacing schema */
$space-x-small: 0.25em;
$space-small: 0.5em;
$space-medium: 1em;
$space-large: 2em;
$space-x-large: 3em;
$space-xx-large: 4em;
$space-xxx-large: 6em;
$space-navbar: 4em;

/** text schema */
$font-sans: "YakuhanJP","游ゴシック Medium",YuGothic,YuGothicM,"Noto Sans JP",sans-serif;
$font-serif: "YakuhanMP","ShipporiMincho","Zilla Slab""游明朝","Yu Mincho",YuMincho,"Hiragino Mincho Pro",serif;
$font-mono: monospace;
$type-base: calc(0.8em + 0.5vw);
$type-base-medium: calc(0.7em + 0.6vw);
$type-base-large: 1.2em;
$type-xxx-small: 55%;
$type-xx-small: 65%;
$type-x-small: 75%;
$type-small: 85%;
$type-medium: 100%;
$type-large: 125%;
$type-x-large: 150%;
$type-xx-large: 200%;
$type-xxx-large: 275%;

/** screen */
$max-screen-width: 960px;
$max-screen-width-large: 1440px;