@keyframes hop {
    0% {
        transform: translateY(0px);
    }
    20% {
        transform: translateY(4px);
    }
    to {
        transform: translateY(0px);
    }
}

@keyframes hop-right {
    0% {
        transform: translateX(0px);
    }
    20% {
        transform: translateX(4px);
    }
    to {
        transform: translateX(0px);
    }
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(6rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}