@use './base/def' as *;

#nprogress .bar {
    background: $color-salmon;
}

.visually-hidden {
    position: absolute;
    top: 0;
    z-index: 999;
    opacity: 0;
    color: #000;
    background-color: #fff;
    font-weight: 700;
    font-family: sans-serif;
    border: 2px solid #000;
    pointer-events: none;
}

body.lang--cn {
    font-family: "YakuhanMP",SimSun,"Zilla Slab","游明朝","Yu Mincho",YuMincho,"Hiragino Mincho Pro",serif !important;
    * {
        font-family: "Zilla Slab",SimSun,"游明朝","Yu Mincho",YuMincho,"Hiragino Mincho Pro",serif !important;
    }
}