@use './def' as *;

*,
*::before,
*::after {
    box-sizing: border-box;
}

*::selection,
*::-moz-selection {
    color: $color-text;
    background-color: $color-blurple;
}

html, body {
    margin: 0;
}

html {
    font-size: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-padding: 10em 0 0 0;
    min-height: calc(var(--vh,1vh)*100);
    @media (max-width: 46.4em) {
        font-size: 90.9%;
    }
}

body {
    overflow-x: hidden;
    margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    padding: 0;
    font-family: $font-sans;
    color: $color-text;
    column-rule: $color-text;
    background-color: $color-sheet;
    @supports (-webkit-touch-callout: none) {
        min-height: calc(var(--vh,1vh)*100);
    }
}

main {
    padding-top: 8em;
    padding-top: max(8em, 20vmin);
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    text-decoration-thickness: 0.1em;
    text-decoration-width: 0.1em;
}

a, a:visited {
    color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: $space-medium 0 $space-x-small 0;
    padding: 0;
    font-weight: 700;
    line-height: 1.25;
}

h1 {
    margin: 0 0 $space-x-small;
    font-size: $type-xx-large;
    line-height: 1;
    letter-spacing: -0.03em;
    @media (min-width: 600px) {
        font-size: $type-xxx-large;
    }
}

h2 {
    font-size: calc($type-xx-large - 25%);
    @media (min-width: 600px) {
        font-size: $type-xx-large;
    }
}

h3 {
    font-size: calc($type-x-large - 15%);
    @media (min-width: 600px) {
        font-size: $type-x-large;
    }
}

h4,
h5,
h6 {
    font-size: $type-large;
}

p,
ul,
ol {
    margin: 0 0 $space-medium 0;
    padding: 0;
    line-height: 1.5;
}

ul,
ol {
    margin-left: 1em;
}

li {
    margin: 0 0 $space-small 0;
}

li ul,
li ol {
    margin: $space-small 0 $space-medium $space-medium;
}

small {
    font-size: $type-x-small;
    line-height: 1.5;
}

del {
    text-decoration: line-through;
}

img,
svg,
video {
    max-width: 100%;
    height: auto;
}

figure {
    position: relative;
    margin: $space-small auto $space-large auto;
    width: 100%;
    text-align: center;
    & img {
        display: block;
        margin: 0 auto;
        box-shadow: 2rem 2rem 6rem $color-shadow;
    }
    & a {
        border-bottom: 0;
    }
}

figcaption {
    margin: $space-medium 0 $space-medium 0;
    font-style: italic;
    font-weight: normal;
    font-size: $type-x-small;
    line-height: 1.3;
}

picture {
    display: block;
    & > img {
        display: block;
    }
}

hr {
    width: 100%;
    height: 0.2rem;
    display: block;
    clear: both;
    margin: $space-xx-large auto;
    padding: 0;
    border: 0;
    background: linear-gradient(
        to right,
        $color-teal 0%,
        $color-blurple 100%
    );
}

blockquote {
    quotes: none;
    & p {
        &::before {
            content: '“';
            display: inline;
        }
        &::after {
            content: '”';
            display: inline;
        }
    }
    & cite {
        display: block;
        font-size: $type-x-small;
        font-weight: 700;
    }
}

embed,
iframe,
object {
    max-width: 100%;
    border: 0;
}

table {
    width: 100%;
    border-spacing: 1rem;
}

thead {
    text-align: left;
}

th {
    text-align: left;
    font-weight: 400;
}